import { createContext, useMemo, useState } from "react"
import { Nav } from "rsuite"

import { Modal } from "components/Modal/Modal"

import {
  DOWNLOAD_URI,
  FILES_URI,
  FLARESOLVER_URI,
  JACKETT_URI,
  RADARR_URI,
  SONARR_URI,
} from "../constants"

const urls = {
  radarr: {
    url: RADARR_URI,
    title: "Radarr",
  },
  sonarr: {
    url: SONARR_URI,
    title: "Sonarr",
  },
  files: {
    url: FILES_URI,
    title: "Fichiers",
  },
  jackett: {
    url: JACKETT_URI,
    title: "Jackett",
  },
  download: {
    url: DOWNLOAD_URI,
    title: "Downloads",
  },
  flaresolver: {
    url: FLARESOLVER_URI,
    title: "Flaresolver",
  },
} as const

export type AppsContextType = {
  appsNavigate: (app: keyof typeof urls, url: string) => void
  close: () => void
}

export const AppsContext = createContext<AppsContextType>({
  appsNavigate: () => undefined,
  close: () => undefined,
})

export function AppsContextProvider(props: { children: React.ReactNode }) {
  const { children } = props

  const [appUrl, setAppUrl] = useState<string | undefined>(undefined)
  const [activeTab, setActiveTab] = useState<keyof typeof urls>(
    Object.keys(urls)[0] as keyof typeof urls,
  )

  function appsNavigate(app: keyof typeof urls, url: string) {
    setActiveTab(app)
    setAppUrl(url)
  }

  const url = useMemo(
    () => (!!activeTab && !!appUrl ? `${urls[activeTab].url}${appUrl}` : null),
    [activeTab, appUrl],
  )

  return (
    <AppsContext.Provider value={{ appsNavigate, close: () => setAppUrl(undefined) }}>
      {children}
      <Modal
        classPrefix="apps"
        isOpen={!!url}
        onClose={() => setAppUrl(undefined)}
        title={
          <Nav appearance="subtle" activeKey={activeTab}>
            {Object.entries(urls).map(([key, { title, url }]) => {
              return (
                <Nav.Item
                  key={key}
                  eventKey={key}
                  onAuxClick={(e) => {
                    e.preventDefault()
                    if (e.button === 1) {
                      window.open(url, "_blank")?.focus()
                    }
                    appsNavigate(key as keyof typeof urls, "")
                  }}
                  onSelect={(key) => {
                    setActiveTab(key as keyof typeof urls)
                    setAppUrl("/")
                  }}
                >
                  {title}
                </Nav.Item>
              )
            })}
          </Nav>
        }
        style={{
          width: "100%",
          height: "100%",
          padding: 0,
        }}
        size="full"
      >
        <iframe
          key={url}
          allow="clipboard-read; clipboard-write; camera; microphone; speaker-selection; encrypted-media; web-share; display-capture; autoplay; fullscreen; picture-in-picture"
          frameBorder="0"
          id="frame-apps"
          sandbox="allow-presentation allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-scripts allow-popups allow-popups-to-escape-sandbox allow-modals allow-top-navigation allow-top-navigation-by-user-activation allow-downloads"
          scrolling="auto"
          src={url || ""}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Modal>
    </AppsContext.Provider>
  )
}
