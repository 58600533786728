import { IconName, library } from "@fortawesome/fontawesome-svg-core"
import {
  faBars,
  faTrash,
  faSpinner,
  faDownload,
  faFilm,
  faTv,
  faEye,
  faUsers,
  faSearch,
  faAddressBook,
  faBullhorn,
  faRefresh,
  faChevronLeft,
  faChevronRight,
  faCalendar,
  faCircleInfo,
  faIndustry,
  faGlobe,
  faCirclePlay,
  faTag,
  faEarthAmerica,
  faMagnifyingGlass,
  faSliders,
  faWarning,
  faInfo,
  faHourglass,
  faClock,
  faRightFromBracket,
  faCircleRight,
  faTicket,
  faCog,
  faCircleUser,
  faServer,
  faCaretDown,
  faFolder,
  faFolderOpen,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"

library.add(
  faBars,
  faTrash,
  faSpinner,
  faDownload,
  faFilm,
  faTv,
  faEye,
  faUsers,
  faSearch,
  faBullhorn,
  faAddressBook,
  faRefresh,
  faChevronLeft,
  faChevronRight,
  faCalendar,
  faCircleInfo,
  faIndustry,
  faGlobe,
  faCirclePlay,
  faTag,
  faEarthAmerica,
  faMagnifyingGlass,
  faSliders,
  faWarning,
  faInfo,
  faHourglass,
  faClock,
  faRightFromBracket,
  faCircleRight,
  faTicket,
  faCog,
  faCircleUser,
  faSliders,
  faServer,
  faCaretDown,
  faFolder,
  faFolderOpen,
  faLightbulb,
)

type FontIconProps = Omit<FontAwesomeIconProps, "icon"> & {
  icon: IconName
}

export function FontIcon(props: FontIconProps): React.JSX.Element {
  const { icon, ...rest } = props
  return <FontAwesomeIcon icon={icon} {...rest} />
}

export { type IconName, type FontIconProps }
