import { useContext, useRef } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import request from "api/request"
import { Button } from "components/Button/Button"
import { IconButton } from "components/Button/IconButton"
import { SearchInput } from "components/SearchInput/SearchInput"
import { AppsContext } from "contexts/AppsContext"
import { IframeContext } from "contexts/IframeContext"
import { RoleType } from "roles"
import { Downloads } from "views/Downloads/Downloads"

import { AuthRestrict } from "../components/Restrictions/AuthRestrict"
import { RoleRestrict } from "../components/Restrictions/RoleRestrict"

import classes from "./Interface.module.scss"

export function Interface() {
  const navigate = useNavigate()

  const { iframeUrl, iframeIsLoaded, getRef, iframeNavigate } = useContext(IframeContext)
  const { appsNavigate } = useContext(AppsContext)

  const canvasRef = useRef<HTMLIFrameElement | null>(null)

  return (
    <AuthRestrict>
      <div className="relative fullWidth fullHeight">
        <iframe
          key={iframeUrl}
          ref={(ref) => {
            getRef(ref)
            canvasRef.current = ref
          }}
          allow="clipboard-read; clipboard-write; camera; microphone; speaker-selection; encrypted-media; web-share; display-capture; autoplay; fullscreen; picture-in-picture"
          frameBorder="0"
          id="frame-plex"
          sandbox="allow-presentation allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-scripts allow-popups allow-popups-to-escape-sandbox allow-modals allow-top-navigation allow-top-navigation-by-user-activation allow-downloads"
          scrolling="auto"
          src={iframeUrl}
          style={{
            width: "100%",
            height: "100%",
          }}
        />

        {iframeIsLoaded ? (
          <div className={classes.container}>
            <div className={classes.menuBar}>
              <div className={classes.leftButtons}>
                <Button
                  className={classes.ponponButton}
                  onClick={() => {
                    navigate("/")
                    iframeNavigate("")
                  }}
                >
                  Ponpon<span style={{ color: "#e5a00c" }}>.tv</span>
                </Button>
                <div style={{ position: "absolute", left: 120, zIndex: 0, pointerEvents: "all" }}>
                  <SearchInput />
                </div>
              </div>
              <div className={classes.centerButtons}>
                <Button
                  appearance="subtle"
                  style={{ height: 30 }}
                  icon="lightbulb"
                  className={classes.menuBarButton}
                  onClick={() => navigate("/search")}
                >
                  Besoin d'une idée
                </Button>
                <Button
                  appearance="subtle"
                  style={{ height: 30 }}
                  icon="eye"
                  className={classes.menuBarButton}
                  onClick={() => navigate("/requests")}
                >
                  Mes requêtes
                </Button>
              </div>
              <div className={classes.rightButtons}>
                <RoleRestrict role={RoleType.ADMIN}>
                  <IconButton
                    appearance="subtle"
                    style={{ height: 30 }}
                    icon="server"
                    className={classes.menuBarButton}
                    onClick={() => appsNavigate("radarr", "/")}
                    tooltip="Apps"
                  />
                  <IconButton
                    appearance="subtle"
                    style={{ height: 30 }}
                    icon="circle-user"
                    className={classes.menuBarButton}
                    onClick={() => navigate("/admin/users")}
                    tooltip="Utilisateurs"
                  />
                  <IconButton
                    appearance="subtle"
                    style={{ height: 30 }}
                    icon="ticket"
                    className={classes.menuBarButton}
                    onClick={() => navigate("/admin/invitations")}
                    tooltip="Invitations"
                  />
                </RoleRestrict>

                <Downloads />
                <IconButton
                  className={classes.menuBarButton}
                  tooltip="Paramètres"
                  icon="cog"
                  onClick={() => iframeNavigate("settings/web/general")}
                  appearance="subtle"
                />
                <IconButton
                  tooltip="Se déconnecter"
                  className={classes.menuBarButton}
                  icon="right-from-bracket"
                  onClick={async () => {
                    await request.auth.logout()
                  }}
                  appearance="subtle"
                />
              </div>
            </div>
          </div>
        ) : null}
        <Outlet />
      </div>
    </AuthRestrict>
  )
}
