import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Input, InputGroup, Loader, Whisper } from "rsuite"

import request from "api/request"
import { CellContent } from "components/Content/CellContent"
import { FontIcon } from "components/FontIcon/FontIcon"
import { useThrottledState } from "hooks/useThrottledState"
import { RootState } from "services/store"
import { MultiRes } from "types/API"
import { Media, MediaType } from "types/Content"
import { Poppover } from "views/Downloads/Downloads"

import classes from "./SearchInput.module.scss"

type SearchInputProps = {
  style?: React.CSSProperties
}

export function SearchInput<T extends MediaType>(props: SearchInputProps) {
  const { style } = props

  const [response, setResponse] = useState<MultiRes<Media<T>>>()
  const [type] = useState<MediaType>("movie")
  const [isFetching, setIsFetching] = useState(false)
  const [focused, setFocused] = useState(false)
  const [throttledSearchValue, searchValue, setSearchValue] = useThrottledState<string>("", 500)

  const queries = useMemo<any>(
    () => ({
      search: throttledSearchValue || undefined,
      type,
      limit: 20,
    }),
    [throttledSearchValue, type],
  )

  const medias = useSelector((state: RootState) => state.medias.medias)

  const fetch = useCallback(async () => {
    if (!throttledSearchValue) return
    setIsFetching(true)
    const response = await request.medias.search<T>(queries)
    setResponse(response)
    setIsFetching(false)
  }, [queries, throttledSearchValue])

  useEffect(() => void fetch(), [queries, fetch])

  useEffect(() => {
    if (!searchValue) setResponse(undefined)
  }, [searchValue])

  const downloadsContent = (
    <div id="downloadsContent" tabIndex={0} style={{ maxHeight: "70vh", overflowY: "auto" }}>
      {(response?.results.map((item) => medias[item.tmdbId]) || []).map((d) => (
        <div
          tabIndex={0}
          key={d.tmdbId}
          style={{
            maxWidth: 500,
            width: 500,
            overflowY: "auto",
            position: "relative",
          }}
          onMouseUp={() => setFocused(false)}
        >
          <div style={{ padding: "6px 0px" }}>{CellContent(d, "compact")}</div>
        </div>
      ))}
    </div>
  )

  return (
    <>
      <Whisper
        open={!!response?.results.length && !!searchValue && focused}
        onBlur={(e) => {
          let current = e.relatedTarget as HTMLElement | null
          while (current) {
            if (current.id === "downloadsContent") return
            current = current.parentElement
          }
          setFocused(false)
        }}
        onFocus={() => setFocused(true)}
        placement="autoVerticalStart"
        controlId="downloads-control-id"
        speaker={<Poppover content={downloadsContent} />}
      >
        <InputGroup inside className={classes.container} style={style}>
          <InputGroup.Addon>
            {isFetching ? <Loader /> : <FontIcon icon={"magnifying-glass"} />}
          </InputGroup.Addon>
          <Input
            autoComplete="off"
            className={classes.input}
            value={searchValue}
            onChange={setSearchValue}
            placeholder="Time to 🍿"
          />
        </InputGroup>
      </Whisper>
    </>
  )
}
